<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <select-language :selected-language="eventData" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Name"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="eventData.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Name"
              >
                <b-form-group
                  label="Excerpt"
                  label-for="desc"
                >
                  <b-form-textarea
                    id="desc"
                    v-model="eventData.exerpt"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Excerpt"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Descripption"
                label-for="blog-content"
                class="mb-2"
              >
                <quill-editor
                  id="blog-content"
                  v-model="eventData.description"
                  :options="snowOption"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group>
                  <h5>Date & TIme</h5>
                  <flat-pickr
                    v-model="eventData.date"
                    class="form-control"
                    :config="{dateFormat: 'Y-m-d'}"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Name"
              >
                <b-form-group
                  label="Organizers"
                  label-for="organizers"
                >
                  <b-form-textarea
                    id="organizers"
                    v-model="eventData.organization"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Organizers"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12">
              <p class="mb-1">
                Event Images
              </p>
              <VueFileAgent
                ref="vueFileAgent"
                v-model="fileRecords"
                :theme="'grid'"
                :multiple="true"
                :deletable="true"
                :meta="true"
                :accept="'image/*,.zip'"
                :max-size="'10MB'"
                :max-files="14"
                :help-text="'Choose images or zip files'"
                :error-text="{
                  type: 'Invalid file type. Only images or zip Allowed',
                  size: 'Files should not exceed 10MB in size',
                }"
                @select="filesSelected($event)"
                @beforedelete="onBeforeDelete($event)"
                @delete="fileDeleted($event)"
              />
            </b-col>
          </b-row>
          <b-row class="dashed">
            <b-col
              v-for="(img, idx) in previewImages"
              :key="idx"
              class="py-3"
              md="2"
            >
              <div class="img-wrap">
                <img
                  :src="img.path"
                  width="200"
                  class="px-5"
                >
                <feather-icon
                  icon="DeleteIcon"
                  class="delete-img"
                  size="20"
                  @click="deleteImage(img.id)"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      :src="preview"
                      height="200"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <b-form-file
                        accept=".jpg, .png, .gif"
                        placeholder="Choose file"
                        @change="onChange"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                variant="primary"
                class="mr-1"
                @click="updateEvent(eventData.id)"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable no-restricted-globals */
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import Vue from 'vue'
import VueFileAgent from 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'
import store from '@/store'
import events from '../events'
import eventsModule from '../eventsModule'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'

Vue.use(VueFileAgent)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    flatPickr,
    selectLanguage,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      eventData: {},
      previewImages: [],
      fileRecords: [],
      fileRecordsForUpload: [],
      preview: null,
      image: null,
      required,
    }
  },
  mounted() {
  },
  setup() {
    const EVENTS_DATA_MODULE = 'eventsModule'
    // const stor = useStore

    // Register module
    if (!store.hasModule(EVENTS_DATA_MODULE)) store.registerModule(EVENTS_DATA_MODULE, eventsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_DATA_MODULE)) store.unregisterModule(EVENTS_DATA_MODULE)
    })

    const {
      addingProduct, eventsForm,
    } = events()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const { formData, setFormData } = handleFormData()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      refInputEl,
      refPreviewEl,
      addingProduct,
      eventsForm,
      formData,
      setFormData,
    }
  },
  created() {
    this.showEvent()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.eventData.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangeFile(e) {
      this.eventData.file = e.target.files
    },
    showEvent() {
      axios.get(`get/event/${this.$router.currentRoute.params.id}`).then(res => {
        this.eventData = res.data.success
        this.previewImages = res.data.success.file
        this.preview = res.data.success.image
      })
    },
    updateEvent(id) {
      this.$refs.infoRules.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('title', this.eventData.title)
          formData.append('exerpt', this.eventData.exerpt)
          formData.append('image', this.eventData.image)
          formData.append('description', this.eventData.description)
          formData.append('date', this.eventData.date)
          formData.append('organization', this.eventData.organization)
          formData.append('language_id', this.eventData.language_id)
          this.fileRecords.map(files => formData.append('file[]', files.file))
          axios.post(`edit/event/${id}`, formData).then(res => {
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Edited Successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/events/list')
            }
          })
        }
      })
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(fileRecord => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      // eslint-disable-next-line no-alert
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord)
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
    deleteImage(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`delete/event/file/${id}`).then(res => {
            // eslint-disable-next-line no-unused-expressions
            this.showEvent()
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.img-wrap {
  position: relative;
}

.img-wrap img {
    border-radius: 5px;
}
.img-wrap .delete-img {
  position: absolute;
  top: -14px;
  left: 10rem;
}
.img-wrap .delete-img:hover {
  cursor: pointer;
}
.dashed {
    border: dashed;
    margin: 1rem;
    padding: 0rem;
}
</style>
